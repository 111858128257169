/* eslint-disable */
import React from "react"
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// import { Link } from "gatsby"

import { LayoutBase as Layout } from "layouts";
import {
  HeroSvg,
  SEO,
  HPRow
} from 'components';
import createScrollToHandler from 'utils/scrollToHandler';

import "slick-carousel/slick/slick.css";
import "styles/page-home.scss";

/**
 * IndexPage
 * @param {*} param0
 */
const IndexPage = ({ pageContext, location }) => {
  if (pageContext== null) return <></>;

  const { fields: {
    heroShort,
    heroTitle: rawHeroTitle,
    homepageRows
  } } = pageContext.homepageData;

  // parse page rows data
  // filter the items that have fields
  const rows = homepageRows.filter(filterContentfulEntry).map(parseHomeRowFromContentful);

  const docToReactOpts = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children
    },
  };

  return (
    <Layout layoutProps={{ location, footerData: pageContext.footerData, pageNavData: pageContext.pageNavData }} >
      <SEO>
        <title>SEO Consultant Melbourne</title>
        <meta name="description" content="Home Page" />
      </SEO>
      <div className="page-content-wrapper">
        <div className="hero">
          <div className="hero__inner">
            <article className="hero__text-wrapper">
              <h1 className="h1 hero__heading">
                {documentToReactComponents(rawHeroTitle, docToReactOpts)}
              </h1>
              <p>{heroShort}</p>
              <a className="hero__go-to" href="#service" onClick={ createScrollToHandler({to: '#service', offset: {top: 70}}) }>
                <i className="hero__go-to-icon zmdi zmdi-long-arrow-down"></i>
              </a>
            </article>
          </div>
          <div className="hero__bg">
            <HeroSvg className="hsvg" />
          </div>
        </div>

        {/*
          start to render home page rows
          currently uses entry id to target the sections
          that would have anchors
        */}
        { rows.map((i, idx, arr) => {
          const blockType = i.blockType;
          const defaultAttr = {
            data: i,
            key: `hpr-${i.id}-${idx}`,
          }
          let block = '';
          let otherAttr = {};

          switch (blockType) {
            case 'textBlock':
              otherAttr['style']= { backgroundColor: '#FAFAFA' };

              if (i.id === '3LhvcMz1HfRiYGOdWzBWQI') { otherAttr['id'] ='service'; }

              block = <HPRow.TextBlock {...defaultAttr} {...otherAttr} />;

              break;

            case 'textBanner':
              block = <HPRow.TextBanner {...defaultAttr} />;
              break

            case 'featureBlock':
              const prev = arr[Math.max(idx - 1, 0)];

              block = <HPRow.FeatureBlock {...defaultAttr} />;

              // if there is a consecutive feature block, put a horizontal line
              if(prev.blockType == 'featureBlock') {
                const {key , ...rest} = defaultAttr
                block = (
                  <React.Fragment key={key}>
                    <div className="side-margin">
                      <hr className="block-mw" />
                    </div>
                    <HPRow.FeatureBlock {...rest} />
                  </React.Fragment>
                );
              }
              break;

            case 'featureList':
              if(i.id === '7M58RCWLcDHqeFkRy48At2') { otherAttr['id']= 'work'; }
              block = <HPRow.FeatureList {...defaultAttr} {...otherAttr} />;
              break

            case 'showcase':
              block = <HPRow.Showcase {...defaultAttr} />;
              break

            default:
              break;
          }

          return block;
        })}

      </div>
    </Layout>
  );
};


/* ================== Helper Functions ================== */

  // in contentful, draft content seems to be listed in the json response
// but they dont have the fields
function filterContentfulEntry(entry) {
  return entry.hasOwnProperty('fields')
}

/**
 * @name parseBrandLogoFromContentful
 * @param {Object} entry
 */
function parseBrandLogoFromContentful (entry) {
  const { title, file } = entry.fields;

  return {
    id: entry.sys.id,
    name: title,
    url: file.url
  }
}


/**
 * @name parseHomeRowFromContentful
 * @param {Object} entry
 *
 * parse data from contentful response and transform it into the format that
 * page section component needs
 */
function parseHomeRowFromContentful (entry) {
  const { fields: thisFields, sys } = entry;
  const blockType = sys.contentType.sys.id

  let obj = {
    id: sys.id,
    blockType,
    fields: thisFields
  };

  switch (blockType) {
    case 'featureBlock':
      obj = {
        ...obj,
        fields: {
          mainContent: { ...thisFields.mainContent.fields },
          subfeatures: thisFields.subfeatures ? thisFields.subfeatures.filter(filterContentfulEntry).map(subItem => ( {...subItem.fields, id: subItem.sys.id })) : []
        }
      };
      break;

    case 'showcase':
      obj = {
        ...obj,
        fields: {
          items : thisFields.items ? thisFields.items.filter(filterContentfulEntry).map(parseBrandLogoFromContentful) : [],
          title: thisFields.title
        }
      }
      break;

    case 'featureList':
      obj = {
        ...obj,
        fields: {
          title: thisFields.title,
          items: thisFields.items ? thisFields.items.filter(filterContentfulEntry).map(subItem => ({...subItem.fields, id: subItem.sys.id})) : []
        }
      }
      break;

    default:
      break;
  }

  return obj;
}

export default IndexPage
